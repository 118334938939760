import Vue from 'vue'
import router from '@/router'
import axios from 'axios'
const contentType = 'application/json;charset=UTF-8'
const requestTimeout = 5000
const successCode = [200, 0]



const instance = axios.create({
    timeout: requestTimeout,
    headers: {
        'Content-Type': contentType,
      },
})

// 请求拦截
instance.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('token')
    // const token = 'tb_api_64995E84126535CA6205630F3DD1D962'
    if (token) {
      config.headers.token = token
    }
    return config
  },
  (error) => {
    return Promise.reject(error)
  }
)
// 响应拦截
instance.interceptors.response.use(
  (response) => {
    const { data } = response
    const { code } = data
    // 操作正常Code数组
    const codeVerificationArray = isArray(successCode)
      ? [...successCode]
      : [...[successCode]]
    // 是否操作正常
    if (codeVerificationArray.includes(code)) {
      return data
    } else {
      if (code === 401) {
          localStorage.removeItem('token')
          localStorage.removeItem('user')
          setTimeout(()=>{
              router.push({path: '/login'})
          }, 2000)
      }
      console.log(data)
      Vue.prototype.$notify({
          type: 'error',
          message: data.msg,
          duration: 2000
      })
      return Promise.reject(data.msg)
    }
  },
  (error) => {
    if (error.response && error.response.data) {
      return Promise.reject(error)
    } else {
      let {message} = error
      if (message === 'Network Error') {
        message = '后端接口连接异常'
      }
      if (message.includes('timeout')) {
        message = '后端接口请求超时'
      }
      if (message.includes('Request failed with status code')) {
        const code = message.substr(message.length - 3)
        message = '后端接口' + code + '异常'
      }
      // Vue.prototype.$notify({
      //   type: 'error',
      //   message: `后端接口未知异常`,
      // })
      // Vue.prototype.$baseMessage(message || `后端接口未知异常`, 'error')
      return Promise.reject(error)
    }
  }
)


function isArray(arg) {
  if (typeof Array.isArray === 'undefined') {
    return Object.prototype.toString.call(arg) === '[object Array]'
  }
  return Array.isArray(arg)
}

export default instance

