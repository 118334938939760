import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

const store = new Vuex.Store({
    state: {
        keywords: '',
        token: '',
        user: {
            full_name: ''
        }
    },
    mutations: {
        tokenChange(state, val) {
            if (val!=''){
                this.state.token = val
            }
        },
        userChange(state, val) {
            if (val!=''){
                this.state.user = val
            }
        },
        keywordsChange(state, val) {
            this.state.keywords = val
            // 写入storage
            if (val !== '') {
                let KeyName = 'keywords_history'
                let keyHistory = localStorage.getItem(KeyName)
                if (keyHistory) {
                    keyHistory = JSON.parse(keyHistory)
                    // 判断是否重复
                    let isRepeat = false
                    keyHistory.map((item) => {
                        if (item === val) {
                            isRepeat = true
                        }
                    })
                    // 不重复时才写入
                    if (!isRepeat) {
                        // 最多存储10个，按照unshift方式入列
                        if (keyHistory.length < 10) {
                            // 小于10个的情况下
                            keyHistory.unshift(val)
                            localStorage.setItem(KeyName, JSON.stringify(keyHistory))
                        } else {
                            // 大于10个则自动移除最旧的一个
                            keyHistory.pop()
                            keyHistory.unshift(val)
                            localStorage.setItem(KeyName, JSON.stringify(keyHistory))
                        }
                    }
                } else {
                    let newKeyHistory = []
                    newKeyHistory.unshift(val)
                    localStorage.setItem(KeyName, JSON.stringify(newKeyHistory))
                }
            }
        }
    },
    actions: {},
    modules: {},
})

export default store
