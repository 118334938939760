var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"nav-bg"},[(_vm.mode === 'main')?_c('div',{staticClass:"nav-bg-top",style:({
              borderBottom: 'solid 4vw ' + (_vm.navColor === '' ? '#A91728' : _vm.navColor),
              borderRight: 'solid 2vw rgba(0,0,0, 0)'
           })}):_vm._e(),(_vm.mode === 'sub')?_c('div',{staticClass:"nav-bg-top",style:({
              width: '100%',
              borderBottom: 'solid 3.7vw ' + (_vm.navColor === '' ? '#A91728' : _vm.navColor),
           })}):_vm._e(),(_vm.mode === 'main')?_c('div',{staticClass:"nav-bg-bottom",style:({
            backgroundColor: (_vm.navColor === '' ? '#A91728' : _vm.navColor)
          })}):_vm._e(),(_vm.mode === 'sub')?_c('div',{staticClass:"nav-bg-bottom",style:({
            backgroundColor: '#FDF6E4'
          })}):_vm._e(),_c('div',{staticClass:"nav-menu"},[_c('ul',{staticClass:"nav-list-ul1"},_vm._l((_vm.navBar),function(item,index){return _c('li',{directives:[{name:"show",rawName:"v-show",value:(!item.hidden),expression:"!item.hidden"}],key:index,staticClass:"nav-list-lv1",class:(item.name === _vm.$route.name) || (item.name === _vm.$route.meta.pname) ? 'nav-list-lv1-hover' : '',on:{"mousemove":function($event){return _vm.showNavBar(index)},"mouseout":function($event){return _vm.hideNavBar(index)}}},[_c('a',{class:(item.name === _vm.$route.name) ? 'li-a-hover' : '',on:{"click":function($event){return _vm.navTo(item.name)}}},[_vm._v(_vm._s(item.meta.title))]),(_vm.mode === 'main')?_c('ul',{staticClass:"nav-list-lv2-group",class:(item.name !== _vm.$route.name) && (item.name !== _vm.$route.meta.pname) ? 'hide': ''},_vm._l((item.children),function(subitem,subindex){return _c('li',{directives:[{name:"show",rawName:"v-show",value:(!subitem.hidden),expression:"!subitem.hidden"}],key:subindex,staticClass:"nav-list-lv2 font-18"},[_c('a',{class:(subitem.name === _vm.$route.name) ? 'li-a-hover' : '',on:{"click":function($event){return _vm.navTo(subitem.name)}}},[_vm._v(_vm._s(subitem.meta.title))])])}),0):_vm._e(),(_vm.mode === 'sub')?_c('ul',{staticClass:"nav-list-lv2-group",class:(item.name !== _vm.$route.name) && (item.name !== _vm.$route.meta.pname) ? 'hide': ''},_vm._l((item.children),function(subitem,subindex){return _c('li',{directives:[{name:"show",rawName:"v-show",value:(!subitem.hidden),expression:"!subitem.hidden"}],key:subindex,staticClass:"sub-nav-list-lv2 font-18"},[_c('a',{class:(subitem.name === _vm.$route.name) ? 'sub-li-a-hover' : '',style:({
                        color: (subitem.name === _vm.$route.name) ? _vm.navColor : 'black'
                      }),on:{"click":function($event){return _vm.navTo(subitem.name)}}},[_vm._v(_vm._s(subitem.meta.title))])])}),0):_vm._e(),_c('div',{staticClass:"nav-down-arrow",class:(item.name !== _vm.$route.name) && (item.name !== _vm.$route.meta.pname) ? 'hide': ''})])}),0)])])
}
var staticRenderFns = []

export { render, staticRenderFns }