import Vue from 'vue'
import App from './App.vue'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import router from "@/router";
import store from './store/index'

Vue.config.productionTip = false
Vue.use(ElementUI)
// 根据类型跳转
Vue.prototype.$navToByType = function (item) {
  if (item.val) {
    if (item.type === 'article') {
      this.$handleArticle(item.val)
    } else if(item.type === 'link') {
      this.$navOpen(item.val)
    }
  }
}
// 路由跳转
Vue.prototype.$navTo = function (routeName, params= {}) {
  if (this.$route.name !== routeName) {
    this.$router.push({name: routeName, query: params})
  }
}
// 外链打开
Vue.prototype.$navOpen = function (url) {
  if (url) {
    window.open(url)
  }
}
// 文章详情跳转
Vue.prototype.$handleArticle = function (aid){
  if (aid > 0) {
    this.$router.push({name: 'articleDetail', query: {id: aid}})
  }
}
// 文章列表跳转
Vue.prototype.$handleArticleList = function (params){
  this.$router.push({name: 'article', query: params})
}
// 退出登录
Vue.prototype.$logout = function (){
  ElementUI.MessageBox.confirm('确认退出登录吗?', '').then(()=>{
    localStorage.removeItem('token')
    localStorage.removeItem('user')
    window.location.reload()
  })
}
new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')
