<template>
  <div id="app" class="font-yahei">
    <Header :content="content" :mode="mode" :nav-color="navColor"></Header>
    <router-view></router-view>
    <Footer :footer="content.footer" :bg-color="bgColor" :sub-bg-color="subBgColor"></Footer>
  </div>
</template>

<script>
import Footer from '@/components/Footer'
import Header from "@/components/Header"
import { pageConfig } from "@/api/common"

export default {
  name: 'App',
  components: { Header, Footer },
  data() {
    return {
      navColor: '',
      bgColor: '',
      subBgColor: '',
      mode: '',
      // 配置数据
      content: {
        footer: {
          about: '',
          sitemap: '',
          statement: '',
          worktime: '',
          tips: '',
          public: {
            path: ''
          },
          miniprog: {
            path: ''
          },
          owner: '',
          record: '',
        },
        logo: {
          path: '',
        },
        search_keys: [],
        site_name: '',
        site_name_en: ''
      },
    }
  },
  created() {
    console.log('LoadToken')
    // 判断是否登录
    var token = localStorage.getItem('token')
    var user = localStorage.getItem('user')
    // 写入$store
    if (token && user) {
      this.$store.commit('tokenChange', token)
      this.$store.commit('userChange', JSON.parse(user))
    }
    // 请求配置数据 - 根据last_time判断是否更新 lovalstorage缓存
    this.fetchConfig()
  },
  watch: {
    // 监听路由变化动态渲染header和footer
    $route() {
      this.navColor = this.$route.meta.navColor
      this.bgColor = this.$route.meta.bgColor
      this.subBgColor = this.$route.meta.subBgColor
      this.mode = this.$route.meta.mode
    },
  },
  methods: {
    fetchConfig() {
      // const content = localStorage.getItem('page_config_common')
      // if (content) {
      //   this.content = JSON.parse(content)
      // }
      // 公共法律服务
      return pageConfig('get', {type: 'common'}).then(res => {
        this.content = res.data.content
        // const lastTime = localStorage.getItem('page_config_common_last_time')
        // // const lastTime = 0
        // if (res.data.last_time !== parseInt(lastTime)) {
        //   this.content = res.data.content
        //   // 写入缓存
        //   localStorage.setItem('page_config_common', JSON.stringify(this.content))
        //   localStorage.setItem('page_config_common_last_time', res.data.last_time)
        // }
      })
    },
  }
}
</script>

<style>
.main-width-625{
  width: 62.5vw;
  margin: 0 auto;
}
.text-center{
  text-align: center;
}
.hide{
  display: none;
}
.flex-space-between{
  display: flex;
  justify-content: space-between;
  align-items: center;
}
/*swiper分页样式*/
.my-swiper-pagination{
  width: 1vw;
  height: 1vw;
  margin: 0 0.5vw;
  display: inline-block;
  border-radius: 100%;
  background: #D9D9D9;
}
.my-swiper-pagination-active{
  opacity: 1;
  background: #A91728;
}
.cursor-hand{
  cursor: pointer;
}
.my-cascader{
  width: 80%;
}
/*通用字体类型*/
.font-bold{
  font-weight: bold;
}
.font-bolder{
  font-weight: bolder;
}
.font-heiti {
  font-family: '黑体';
}
.font-yahei{
  font-family: 'Microsoft YaHei', 'SimSun';
}
.font-songti{
  font-family: '宋体';
}
/*通用字体样式*/
.font-36{
  font-size: 1.875vw;
}
.font-32{
  font-size: 1.66vw;
}
.font-30{
  font-size: 1.5625vw;
}
.font-28{
  font-size: 1.45vw;
}
.font-26{
  font-size: 1.35vw;
}
.font-24{
  font-size: 1.25vw;
}
.font-22{
  font-size: 1.1458vw;
}
.font-20{
  font-size: 1.0416vw;
}
.font-18{
  font-size: 0.9375vw;
}
.font-16{
  font-size: 0.8333vw;
}
.font-14{
  font-size: 0.7921vw;
}
.font-12{
  font-size: 0.625vw;
}
.font-9{
  font-size: 0.4687vw;
}
.cursor-pointer{
  cursor: pointer;
}
.color-gold{
  color: #D0B479!important;
}
.color-red{
  color: #A91728!important;
}
/*elment-ui样式自适应*/
.el-message-box{
  width: 21.875vw!important;
}
.el-message-box__header{
  padding: 0.26vw 0.78vw !important;
}
.el-message-box__content{
  font-size: 0.8vw!important;
  padding: 0.78vw 0.78vw 0.52vw!important;
}
.el-message-box__btns{
  padding: 0.26vw 0.78vw!important;
}
.el-button--small{
  padding: 0.468vw 0.78vw!important;
  font-size: 0.625vw!important;
  border-radius: 0.208vw!important;
}
.el-message-box__headerbtn{
  font-size: 0.8vw!important;
}
.el-button--primary{
  background-color: #A91728!important;
  border-color: #A91728!important;
}
/*消息提示*/
.el-notification{
  width: 17.1875vw!important;
  padding: 0.7291vw 1.354vw 0.7291vw 0.677vw!important;
  align-items: center;
}
.el-notification__content{
  font-size: 0.8vw!important;
}
.el-notification__icon{
  height: 100% !important;
  width: 0.8vw!important;
  /*height: 0.8vw!important;*/
  font-size: 0.8vw!important;
  /*margin-top: 0.8vw!important;*/
}
.el-notification__closeBtn{
  right: 0.3vw!important;
  top: 0;
}
</style>
