import request from "@/api/request";
// import axios from "axios";
// const baseURL = '//fawuapi.vip.cpolar.top'
const baseURL = '/api'
export const qiniuURL = 'http://source.falvren.net/'
// const baseURL = 'http://fawuapi.vip.cpolar.top'
    // RESTful风格的request封装


function RESTful($url, method, data) {
    const config = {
        url: baseURL + $url,
        method,
    };
    if (method === 'get') {
        config.params = data
    } else {
        config.data = data
    }
    return request(config)
}

// 用户信息（获取用户信息，注册等）
export function user(method, data) {
    return RESTful('/client/auth/user', method, data)
}
// 登录接口
export function token(method, data) {
    return RESTful('/client/auth/token', method, data)
}
// 短信发送接口
export function sms(method, data) {
    return RESTful('/client/auth/sms', method, data)
}
// 律师列表
export function lawyer(method, data) {
    return RESTful('/client/index/lawyer', method, data)
}
// 律所列表
export function lawOffice(method, data) {
    return RESTful('/client/index/lawOffice', method, data)
}
// 页面配置
export function pageConfig(method, data) {
    return RESTful('/client/index/pageConfig', method, data)
}
// 文章列表
export function articleClass(method, data) {
  return RESTful('/client/index/articleClass', method, data)
}
// 文章列表
export function article(method, data) {
    return RESTful('/client/index/article', method, data)
}
// 专长列表
export function speciality(method, data) {
    return RESTful('/client/index/speciality', method, data)
}
// 城市列表
export function city(method, data) {
    return RESTful('/client/index/city', method, data)
}
// 课程分类
export function courseType(method, data) {
    return RESTful('/client/index/courseType', method, data)
}
// 课程
export function course(method, data) {
    return RESTful('/client/index/course', method, data)
}
// 课时打卡
export function userSection(method, data) {
    return RESTful('/client/index/userSection', method, data)
}
// 智能咨询
export function helpRecord(method, data) {
    return RESTful('/client/index/helpRecord', method, data)
}

