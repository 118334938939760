<template>
  <div>
    <div class="nav-search">
      <i class="el-icon-search search-icon"></i>
      <input v-model="search" class="search" type="text" placeholder="请输入你想要搜索的内容" @keyup.enter="handleToSearch" >
      <button
          class="search-btn"
          :style="{
            backgroundColor: navColor === '' ? '#A91728' : navColor
          }"
          @click="handleToSearch"
      >搜索</button>
    </div>
    <div class="search-text">
      <span v-if="searchKeys" >大家都在搜:</span>
      <span v-for="(item, index) in searchKeys" :key="index" @click="keywordsToSearch(item)">{{ item }}</span>
    </div>
  </div>
</template>

<script>
export default {
  name: "cSearch",
  props: {
    navColor: {
      type: String,
      default: '#A91728'
    },
    searchKeys: {
      type: Array,
      default: function (){
        return []
      }
    }
  },
  data() {
    return {
      search: '',
    }
  },
  mounted() {
    // const siteInfo = JSON.parse(localStorage.getItem('siteInfo'))
    // if (siteInfo) {
    //   this.search_keys = siteInfo.search_keys.split(',')
    // }
  },
  methods: {
    keywordsToSearch(item) {
      this.search = item
      this.handleToSearch()
    },
    handleToSearch() {
      this.$store.commit('keywordsChange', this.search)
      this.$router.push({ name:'article'})
    },
  }
}
</script>

<style scoped>
.search-icon{
  font-size: 1.3vw;
  color: #cbcbcb;
  position: relative;
  right: -2.5vw;
}
.nav-search {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  margin-top: 2vw;
}

.search {
  width: 24vw;
  height: 3vw;
  font-size: 1.2vw;
  padding-left: 3vw;
  color: #cbcbcb;
  border-radius: 1vw 0 0 1vw;
  /*border-left: solid 1px lightgray;*/
  /*border-top: solid 1px lightgray;*/
  /*border-bottom: solid 1px lightgray;*/
  /*border-right: none;*/
  border: none;
}
.search:focus{
  outline: 0;
}
.search::placeholder{
  color: #cbcbcb;
}

/*.nav-search :deep(.el-input__inner) {*/
/*  height: 55px;*/
/*  background-color: white;*/
/*  border: 1px solid #e5e5e5;*/
/*  border-radius: 4px 0 0 4px;*/
/*}*/

/*.search :deep(.el-input__inner::placeholder) {*/
/*  !*color: black;*!*/
/*  font-size: 18px;*/
/*  padding-left: 8px;*/
/*}*/

/*.search :deep(.el-input__icon) {*/
/*  color: #000000;*/

/*}*/

.search-btn {
  width: 8vw;
  height: 3vw;
  /*background-color: #A91728;*/
  color: #ffffff;
  font-size: 1.2vw;
  text-align: center;
  border-radius: 0px 1vw 1vw 0px;
  cursor: pointer;
  border: none;
}

.search-text {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 0.8vw;
  color: #000000;
  padding: 1.5vw 0 2.5vw 0;
}
.search-text span {
  margin: 0 0.3vw;
  cursor: pointer;
}
</style>
