<template>
  <!--      导航条-->
    <div class="nav-bg">
      <div
          v-if="mode === 'main'"
          class="nav-bg-top"
           :style="{
              borderBottom: 'solid 4vw ' + (navColor === '' ? '#A91728' : navColor),
              borderRight: 'solid 2vw rgba(0,0,0, 0)'
           }"
      ></div>
<!--      子页面模式-->
      <div
          v-if="mode === 'sub'"
          class="nav-bg-top"
          :style="{
              width: '100%',
              borderBottom: 'solid 3.7vw ' + (navColor === '' ? '#A91728' : navColor),
           }"
      ></div>
<!--      三大模块模式-->
      <div
          v-if="mode === 'main'"
          class="nav-bg-bottom"
          :style="{
            backgroundColor: (navColor === '' ? '#A91728' : navColor)
          }"
      ></div>
<!--      子页面模式-->
      <div
          v-if="mode === 'sub'"
          class="nav-bg-bottom"
          :style="{
            backgroundColor: '#FDF6E4'
          }"
      ></div>
      <div class="nav-menu">
          <ul class="nav-list-ul1">
            <li
                @mousemove="showNavBar(index)"
                @mouseout="hideNavBar(index)"
                v-for="(item, index) in navBar"
                :key="index" class="nav-list-lv1 "
                :class="(item.name === $route.name) || (item.name === $route.meta.pname) ? 'nav-list-lv1-hover' : '' "
                v-show="!item.hidden"
            >
              <a :class="(item.name === $route.name) ? 'li-a-hover' : '' " @click="navTo(item.name)">{{ item.meta.title }}</a>
<!--              父级页面模式-->
              <ul v-if="mode === 'main'" :class="(item.name !== $route.name) && (item.name !== $route.meta.pname) ? 'hide': ''" class="nav-list-lv2-group">
                <li v-show="!subitem.hidden" v-for="(subitem, subindex) in item.children" :key="subindex" class="nav-list-lv2 font-18" >
                  <a @click="navTo(subitem.name)" :class="(subitem.name === $route.name) ? 'li-a-hover' : ''">{{ subitem.meta.title }}</a>
                </li>
              </ul>
<!--              子页面模式-->
              <ul v-if="mode === 'sub'" :class="(item.name !== $route.name) && (item.name !== $route.meta.pname) ? 'hide': ''" class="nav-list-lv2-group">
                <li v-show="!subitem.hidden" v-for="(subitem, subindex) in item.children" :key="subindex" class="sub-nav-list-lv2 font-18" >
                  <a
                      @click="navTo(subitem.name)"
                      :class="(subitem.name === $route.name) ? 'sub-li-a-hover' : ''"
                      :style="{
                        color: (subitem.name === $route.name) ? navColor : 'black'
                      }"
                  >{{ subitem.meta.title }}</a>
                </li>
              </ul>
              <div class="nav-down-arrow" :class="(item.name !== $route.name) && (item.name !== $route.meta.pname) ? 'hide': ''" ></div>
            </li>
          </ul>
      </div>
    </div>
</template>

<script>
export default {
  name: "cNavBar",
  props: {
    mode: {
      type: String,
      default: 'main'
    },
    navColor: {
      type: String,
      default: '#A91728'
    }
  },
  data() {
    return {
      navBar: this.$router.options.routes,
    }
  },
  mounted() {
    // console.log(this.$router.options.routes)
    // this.navBar = this.$router.options.routes
  },
  methods: {
    showNavBar(index) {
      for (let e in this.navBar) {
        this.navBar[e].showChildren = 0
      }
      this.navBar[index].showChildren = 1
    },
    hideNavBar(index,) {
      this.navBar[index].showChildren = 0
    },
    navTo(routeName) {
      if (this.$route.name !== routeName) {
        this.$router.push({name: routeName})
      }
    }
  }
}
</script>

<style scoped>
.hide{
  display: none;
}
/*梯形背景*/
.nav-bg{
  position: relative;
  width: 100%;
  /*margin-top: 0.7vw;*/
}
.nav-bg-top{
  width: 59%;
  /*min-width: 660px;*/
  /*border-right: solid 2vw rgba(0,0,0, 0);*/
}
.nav-bg-bottom{
  width: 100%;
  height: 3vw;
}
/*导航列表*/
.nav-menu{
  width: 62.5%;
  margin: 0 auto;
}
.nav-list{
  /*width: 100%;*/
  /*position: absolute;*/
  /*top: 0;*/
  /*height: 63px;*/
}
.nav-down-arrow{
  position: relative;
  top: 0.8vw;
  margin: 0 auto;
  width: 0;
  border-top: solid 0.6vw #D0B479;
  border-right: solid 0.3vw rgba(0,0,0,0);
  border-left: solid 0.3vw rgba(0,0,0,0);
}
.nav-list-ul1{
  margin: unset;
  padding: 0;
  position: absolute;
  top: 0;
}
/*.nav-list-lv1 a:hover{*/
  /*color: #D0B479;*/
  /*border-bottom: solid 0.25vw #D0B479;*/
  /*padding-bottom: 0.7vw;*/
/*}*/
.nav-list-lv1-hover{
  color: #D0B479!important;
  border-bottom: solid 0.25vw #D0B479!important;
  padding-bottom: 0!important;
}
.nav-list-lv1{
  padding: 1.3vw  0.6vw;
  display: inline-block;
  /*float: left;*/
  list-style: none;
  color: white;
  font-size: 1.5vw;
  cursor: pointer;
  margin-right: 3vw;
}
.nav-list-lv1 a{
  padding: 1.5vw 0;
}
.nav-list-lv2-group{
  padding-top: 0.9vw;
  padding-left: 0;
  position: absolute;
  white-space: nowrap;
}
.nav-list-lv2{
  display: inline-block;
  /*float: left;*/
  list-style: none;
  color: white;
  /*font-size: 0.9vw;*/
  cursor: pointer;
  padding-right: 3vw;
  padding-left: 1vw;
}
.nav-list-lv2:hover{
  color: #D0B479;
}
.li-a-hover{
  color: #D0B479;
}
.sub-li-a-hover{
  /*color: #A91728;*/
  font-weight: bold;
}
.sub-nav-list-lv2{
  display: inline-block;
  /*float: left;*/
  list-style: none;
  color: #333333;
  /*font-size: 0.8vw;*/
  cursor: pointer;
  padding-right: 3vw;
  padding-left: 1vw;
}
.sub-nav-list-lv2:hover{
  color: #A91728;
}
</style>
