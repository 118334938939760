<template>
  <div>
    <div class="main-width-625 nav-header">
      <div class="nav-header-logo">
        <img :src="navLogo" alt="">
      </div>
      <div class="nav-header-list">
        <div class="nav-header-jump">
          <a @click="login" v-show="!$store.state.token" >登录个人中心</a>
          <a  @click="$navTo('user')" v-show="$store.state.token" class="color-red" >{{$store.state.user.full_name}}<span @click="$logout" class="font-12">：退出登录</span></a>
          <span>|</span>
          <a>智能问答</a>
          <span>|</span>
          <a>简体</a>
          <span>|</span>
          <a>移动版</a>
        </div>
        <div class="nav-header-lang">
          <a>ENGLISH</a>
          <a>한국어</a>
          <a>日本语</a>
          <a>DEUTSCH</a>
          <a>FRANÇAIS</a>
          <a>РУССКИЙ</a>
        </div>
      </div>
      <!--        <div class="logo"></div>-->
      <!--        <div class="login">-->
      <!--          <a v-if="isLogin === false" class="login-head-btn" @click="login">登录</a>-->
      <!--          &lt;!&ndash;          <a class="user-info" v-if="isLogin === true"  ><i class="el-icon-user-solid"></i>个人中心</a>&ndash;&gt;-->
      <!--          <el-dropdown @command="handleCommand">-->
      <!--            <span class="el-dropdown-link">-->
      <!--              <a  v-if="isLogin === true"  ><i class="el-icon-user-solid"></i>个人中心</a>-->
      <!--            </span>-->
      <!--            <el-dropdown-menu slot="dropdown">-->
      <!--              &lt;!&ndash;              <el-dropdown-item command="changePwd" >修改密码</el-dropdown-item>&ndash;&gt;-->
      <!--              <el-dropdown-item command="logout">退出登录</el-dropdown-item>-->
      <!--            </el-dropdown-menu>-->
      <!--          </el-dropdown>-->
      <!--        </div>-->
    </div>
  </div>
</template>

<script>
import {sms, token} from "@/api/common";

export default {
  name: "cNavTop",
  data() {
    return {
      navLogo: '/static/img/nav_logo_new.png',
      loginModel: false,
      form: {
        phone: '',
        code: '',
      },
    }
  },
  mounted() {},
  methods: {
    login() {
      this.$navTo('login')
    },
    // 发送短信验证码
    smsSend() {
      if (this.form.phone === '') {
        this.$notify({
          type: 'error',
          message: '请输入手机号'
        })
        return
      }
      //发送短信
      sms('post', {phone: this.form.phone}).then((res) => {
        if (res.code === 200) {
          this.$notify({
            type: 'success',
            message: '发送成功',
          })
        }
      })
    },
    //登录操作
    handleLogin() {
      token('post', this.form).then((res) => {
        if (res.code === 200) {
          localStorage.setItem('token', res.data.token)
          // this.loginModel = false
          window.location.reload()
        }
      })
    },
    // // 退出登录
    // logout() {
    //   localStorage.removeItem('token')
    //   window.location.reload()
    // }
  }
}
</script>

<style scoped>
  .nav-header {
    display: flex;
    justify-content: flex-start;
    padding-top: 1.5vw;
  }

  .nav-header-logo{
    /*min-width: 56px;*/
    /*min-height: 56px;*/
    width: 2.8vw;
    height: 2.8vw;
  }
  .nav-header-logo img{
    width: 100%;
    height: 100%;
  }
  .nav-header-list a{
    cursor: pointer;
  }
  .nav-header-jump{
    font-size: 0.8vw;
    /*font-size: 14px;*/
    /*line-height: 1.5vw;*/
    /*line-height: 24px;*/
    color: #fff;
  }
  .nav-header-jump a{
    padding: 0 0.8vw;
  }
  .nav-header-lang{
    /*font-size: 13px;*/
    /*line-height: 24px;*/
    font-size: 0.6vw;
    /*line-height: 1.5vw;*/
    margin-top: 0.5vw;
    color: #843F00;
  }
  .nav-header-lang a:first-child{
    padding-left: 0.8vw;
  }
  .nav-header-lang a{
    padding: 0 0.5vw;
  }

  .nav-login{
    width: 65%;
    margin: 0 auto;
  }

  /deep/ .el-dialog__close{
    font-size: 1.5vw;
  }
  /deep/ .el-form-item{
    margin-bottom: 2vw;
  }
  /deep/ .el-dialog__header{
    padding: 1.5vw;
  }
  /deep/ .el-dialog__title{
    font-size: 1.5vw;
  }
  /deep/ .el-input__inner{
    font-size: 1.2vw;
    height: 3vw;
    padding-left: 1vw;
  }
  .getcodes {
    display: flex;
  }
  .form {
    width: 80%;
    margin: 0 auto;
  }
  .getBtn {
    margin-left: 1vw;
    background-color: #bd1a2d;
    border: none;
    font-size: 1.2vw;
  }

  .login-btn {
    width: 100%;
    background-color: #bd1a2d;
    font-size: 1.2vw;
    height: 3vw;
  }

  .login-tip {
    font-size: 1.2vw;
    color: #bd1a2d;
    text-align: center;
    margin-top: 1vw;
  }
/*}*/
</style>
