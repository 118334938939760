<template>
  <div>
<!--    子模块header-->
    <div v-if="mode === 'sub'" class="sub-header-bg"
      :style="{
        backgroundColor: navColor === '' ? '#A91728' : navColor
      }"
    >
      <div class="sub-header-body">
        <div class="sub-header-content flex-space-between">
          <div class="sub-header-left">
            <div class="sub-header-siteinfo">
              <div class="sub-header-siteLogo cursor-hand" @click="$navTo('home')">
                <img :src="qiniuURL + content.logo.path" alt="">
              </div>
              <div class="sub-header-siteName">
                <div class="sub-header-siteCN ">{{content.site_name}}</div>
                <div class="sub-header-siteEN">{{content.site_name_en}}</div>
              </div>
            </div>
            <div class="sub-header-search">
              <i class="el-icon-search search-icon"></i>
              <input v-model="search" type="text" placeholder="请输入你要搜索的内容" @keyup.enter="handleToSearch" >
            </div>
          </div>
          <div class="sub-header-right flex-space-between">
            <div class="sub-header-sublogo">
              <img :src="navLogo" alt="">
            </div>
            <div class="sub-header-login">
              <div class="sub-header-login-top ">
                <span @click="login" v-show="!$store.state.token">登录个人中心</span>
                <span @click="$navTo('user')" class="color-gold" v-show="$store.state.token">{{ $store.state.user.full_name }}<a @click="$logout" class="font-12">：退出登录</a></span>
                <span>智能问答</span>
              </div>
              <div class="sub-header-login-bottom ">
                <span>无障碍</span>
                <span>移动版</span>
                <span>English</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <NavBar :mode="mode" :navColor="navColor"></NavBar>
    </div>
<!--    大模块header-->
    <div
        v-if="mode === 'main'"
        class="header-bg"
        :style="{
          backgroundImage: 'url('+$route.meta.bgImg+')'
        }"
    >
      <div class="home-wrapper">
        <NavTop></NavTop>
        <div class="header">
          <img @click="$navTo('home')" :src="qiniuURL+content.logo.path" class="logo" >
        </div>
        <div class="site-title ">{{content.site_name}}</div>
        <div class="en-title">{{content.site_name_en}}</div>
        <Search :navColor="navColor" :search-keys="content.search_keys"></Search>
        <NavBar :navColor="navColor"></NavBar>
      </div>
    </div>
  </div>
</template>

<script>
import NavBar from "@/components/NavBar"
import NavTop from "@/components/NavTop";
import Search from "@/components/Search"
import {qiniuURL} from "@/api/common"

export default {
  name:'cHeader',
  components: { NavTop, NavBar, Search },
  props: {
    mode: {
      type: String,
      default: 'main'
    },
    navColor: {
      type: String,
      default: '#A91728'
    },
    content: {
      type: Object,
      default: function () {
        return {
          logo: {
            path: '',
          },
          search_keys: []
        }
      }
    }
  },
  data() {
    return {
      navLogo: '/static/img/nav_logo_new.png',
      // 子模块的登录弹窗
      loginModel: false,
      form: {
        phone: '',
        code: '',
      },
      // 七牛云和其他配置
      qiniuURL: qiniuURL,
      languages: [
        {
          value: '1',
          label: '简体中文'
        }, {
          value: '2',
          label: 'ENG'
        }
      ],
      value: '',
      search: '',
      bgImg: ''
    }
  },
  methods: {
    handleToSearch() {
      this.$store.commit('keywordsChange', this.search)
      this.$navTo('article')
      this.search = ''
    },
    fetchConfig() {
      // 公共法律服务
      // pageConfig('get', {type: 'common'}).then(res => {
      //   this.content = res.data.content
      // })
    },
    handleTo(url) {
      this.$router.push({path:url})
    },
    // 子模块登录
    login() {
      // this.loginModel = true
      this.$navTo('login')
    },
  }
}
</script>

<style scoped>
/*子模块登录样式*/
.nav-login{
  width: 65%;
  margin: 0 auto;
}

/deep/ .el-dialog__close{
  font-size: 1.5vw;
}
/deep/ .el-form-item{
  margin-bottom: 2vw;
}
/deep/ .el-dialog__header{
  padding: 1.5vw;
}
/deep/ .el-dialog__title{
  font-size: 1.5vw;
}
/deep/ .el-input__inner{
  font-size: 1.2vw;
  height: 3vw;
  padding-left: 1vw;
}
.getcodes {
  display: flex;
}
.form {
  width: 80%;
  margin: 0 auto;
}
.getBtn {
  margin-left: 1vw;
  background-color: #bd1a2d;
  border: none;
  font-size: 1.2vw;
}

.login-btn {
  width: 100%;
  background-color: #bd1a2d;
  font-size: 1.2vw;
  height: 3vw;
}

.login-tip {
  font-size: 1.2vw;
  color: #bd1a2d;
  text-align: center;
  margin-top: 1vw;
}
/*大模块样式*/
.header-bg {
  width: 100%;
  /*height: 43vw;*/
  /*background-image: url(/public/static/img/header_bg.png);*/
  background-size: cover;
  /*background-size: 100% 100%;*/
  background-repeat: no-repeat;
  background-position: center;
}
.logo {
  width: 6vw;
  height: 6vw;
}
.header {
  margin-top: 1.2vw;
  text-align: center;
}
.header img{
  cursor: pointer;
}
.home-wrapper {
  /*background-color: #bd1a2d;*/
}
.site-title {
  /*width: 344px;*/
  font-size: 3vw;
  margin: 0 auto;
  color: #A91728;
  text-align: center;
  font-weight: bolder;
}

.en-title {
  font-size: 1.3vw;
  /*width: 320px;*/
  margin: 0.8vw auto;
  color: #787777;
  text-align: center;
}
/*子页面头部*/
.sub-header-bg{
  /*height: 19.8vw;*/
  /*background-color: #A91728;*/
}

.sub-header-body{
  border-bottom: solid 1px #FFFFFF;
}
.sub-header-content{
  margin: 0 auto;
  width: 72.91vw;
  padding: 5vw 0;
}
.sub-header-left{
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.sub-header-siteinfo{
  display: flex;
}
.sub-header-siteName{
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 2vw;
}
.sub-header-siteCN{
  font-size: 2.5vw;
  color: #FFFFFF;
}
.sub-header-siteEN{
  font-size: 1.25vw;
  color: #FFFFFF;
  text-align: center;
}
.sub-header-siteLogo{
  width: 5.1vw;
  height: 5.38vw;
  overflow: hidden;
}
.sub-header-siteLogo img{
  width: 100%;
  height: 100%;
  filter: drop-shadow(5vw 5vw #FFFFFF);
  top: -5vw;
  left: -5vw;
  position: relative;
}
.sub-header-search .search-icon{
  font-size: 1.2vw;
  position: relative;
  left: 4.2vw;
  color: #333333;
}
.sub-header-search input{
  font-size: 1.2vw;
  border: none;
  border-radius: 1vw;
  padding: 0.83vw 0.83vw 0.83vw 3vw;
  /*width: 28.12vw;*/
  width: 24vw;
  margin-left: 2vw;
}
.sub-header-right{

}
.sub-header-sublogo{
  width: 2.8vw;
  height: 2.8vw;
  overflow: hidden;
}
.sub-header-sublogo img{
  width: 100%;
  height: 100%;
  filter: drop-shadow(4vw 4vw #FFFFFF);
  top: -4vw;
  left: -4vw;
  position: relative;
}
.sub-header-login{
  font-size: 0.83vw;
  color: #FFFFFF;
  /*margin-left: 1vw;*/
}

.sub-header-login-top{
  padding: 0 0.5vw 0.5vw 0;
}
.sub-header-login-top span{
  padding: 0 1vw;
  border-right: solid 1px #FFFFFF;
  cursor: pointer;
}
.sub-header-login-top span:last-child{
  border-right:none;
}
.sub-header-login-bottom{
  padding: 0 0.5vw 0.5vw 0;
}
.sub-header-login-bottom span{
  padding: 0 1vw;
  border-right: solid 1px #FFFFFF;
}
.sub-header-login-bottom span:last-child{
  border-right:none;
}
</style>
