import Vue from 'vue'
import VueRouter from 'vue-router'
Vue.use(VueRouter)

const routes = [
    {
        hidden: true,
        path: '/',
        redirect: '/home',
        meta: {
            title: '',
        },
    },
    {
        path: '/login',
        name: 'login',
        hidden: true,
        meta: {
            mode: 'sub',
            title: '登录注册',
            navColor: '#A91728',
            bgColor: '#A91728',
            bgImg: '',
            subBgColor: '#980028',
        },
        component: () => import('@/views/login/login'),
    },
    {
        path: '/user',
        name: 'user',
        hidden: true,
        meta: {
            mode: 'sub',
            title: '个人中心',
            navColor: '#A91728',
            bgColor: '#A91728',
            bgImg: '',
            subBgColor: '#980028',
        },
        component: () => import('@/views/user/index'),
    },
    {
        path: '/home',
        name: 'home',
        meta: {
            mode: 'main',
            title: '法和律',
            navColor: '#A91728',
            bgColor: '#A91728',
            bgImg: '',
            subBgColor: '#980028',
        },
        component: () => import('@/views/home/Home'),
        children: [
            {
                path: 'inherit',
                name: 'inherit',
                meta: {
                    title: '宣扬法界名人和法律传承文化',
                    pname: 'home',
                    mode: 'sub',
                },
                component: () => import('@/views/home/Inherit'),
            },
            {
                path: 'history',
                name: 'history',
                meta: {
                    title: '宣扬法律历史文化',
                    pname: 'home',
                    mode: 'sub',
                },
                component: () => import('@/views/home/History'),
            },
        ]
    },
    {
        path: '/rule',
        name: 'rule',
        mode: 'main',
        meta: {
            title: '法治之窗',
            navColor: '#1348A4',
            bgColor: '#1348A4',
            bgImg: '',
            subBgColor: '#043A97',
        },
        component: () => import('@/views/rule/Rule'),
        children: [
            {
                path: 'active',
                name: 'active',
                meta: {
                    title: '社会互动模式',
                    pname: 'rule',
                    mode: 'sub',
                    navColor: '#1348A4',
                    bgColor: '#1348A4',
                    subBgColor: '#043A97',
                },
                component: () => import('@/views/rule/Active'),
            },
            {
                path: 'room',
                name: 'room',
                meta: {
                    title: '法家大讲课',
                    pname: 'rule',
                    mode: 'sub',
                    navColor: '#1348A4',
                    bgColor: '#1348A4',
                    subBgColor: '#043A97',
                },
                component: () => import('@/views/rule/Room'),
            },
            {
                path: 'commu',
                name: 'commu',
                meta: {
                    title: '普法教育宣传培训交流互动',
                    pname: 'rule',
                    mode: 'sub',
                    navColor: '#1348A4',
                    bgColor: '#1348A4',
                    subBgColor: '#043A97',
                },
                component: () => import('@/views/rule/Commu'),
            },
            {
                path: 'case',
                name: 'case',
                meta: {
                    title: '案件交流与协助',
                    pname: 'rule',
                    mode: 'sub',
                    navColor: '#1348A4',
                    bgColor: '#1348A4',
                    subBgColor: '#043A97',
                },
                component: () => import('@/views/rule/Case'),
            },
            {
                path: 'welfare',
                name: 'welfare',
                meta: {
                    title: '法律公益援助',
                    pname: 'rule',
                    mode: 'sub',
                    navColor: '#1348A4',
                    bgColor: '#1348A4',
                    subBgColor: '#043A97',
                },
                component: () => import('@/views/rule/Welfare'),
            },
            {
                path: 'promote',
                name: 'promote',
                meta: {
                    title: '法律图书的宣发推广',
                    pname: 'rule',
                    mode: 'sub',
                    navColor: '#1348A4',
                    bgColor: '#1348A4',
                    subBgColor: '#043A97',
                },
                component: () => import('@/views/rule/Promote'),
            },
        ]
    },
    {
        path: '/lawer',
        name: 'lawer',
        meta: {
            title: '法律明白人',
            navColor: '#A91728',
            bgColor: '#A91728',
            bgImg: '',
            subBgColor: '#980028',
        },
        component: () => import('@/views/lawer/Lawer'),
        children: [
            {
                path: 'policy',
                name: 'policy',
                meta: {
                    title: '法律政策',
                    pname: 'lawer',
                    mode: 'sub',
                },
                component: () => import('@/views/lawer/Policy'),
            },
            {
                path: 'area',
                name: 'area',
                meta: {
                    title: '地区落实',
                    pname: 'lawer',
                    mode: 'sub',
                },
                component: () => import('@/views/lawer/Area'),
            },
            {
                path: 'help',
                name: 'help',
                meta: {
                    title: '法律援助',
                    pname: 'lawer',
                    mode: 'sub',
                },
                component: () => import('@/views/lawer/Help'),
            },
            {
                path: 'course',
                name: 'course',
                meta: {
                    title: '法律明白人讲堂',
                    pname: 'lawer',
                    mode: 'sub',
                },
                component: () => import('@/views/lawer/Course'),
            },
            {
                path: 'courseDetail',
                name: 'courseDetail',
                hidden: true,
                meta: {
                    title: '课程详情',
                    pname: 'lawer',
                    mode: 'sub',
                },
                component: () => import('@/views/lawer/CourseDetail'),
            },
        ]
    },
    {
        path: '/article',
        name: 'article',
        hidden: true,
        meta: {
            title: '搜索列表',
            mode: 'sub',
        },
        component: () => import('@/views/article/list'),
        children: [
            {
                path: 'detail',
                name: 'articleDetail',
                hidden: true,
                meta: {
                    title: '详情',
                    pname: 'article',
                    mode: 'sub',
                },
                component: () => import('@/views/article/detail'),
            },
        ]
    },
    {
        path: '/lawyer',
        name: 'lawyer',
        hidden: true,
        meta: {
            title: '律师列表',
            mode: 'sub',
        },
        component: () => import('@/views/lawyer/list'),
        children: [
            {
                path: 'detail',
                name: 'lawyerDetail',
                hidden: true,
                meta: {
                    title: '律师详情',
                    pname: 'lawyer',
                    mode: 'sub',
                },
                component: () => import('@/views/lawyer/detail'),
            },
        ]
    },
]

const router = new VueRouter({
    mode: 'history',
    routes
})

export default router

