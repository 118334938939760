<template>
  <div
      class="footer-body"
      :style="{
        backgroundColor: (bgColor === '' ? '#A91728' : bgColor)
      }"
  >
    <div class="footer-link">
      <div class="footer-link-list main-width-625 flex-space-between ">
        <a class="font-18 cursor-hand" @click="$navToByType(footer.qs)">全方位为您解答相关的法律疑惑问题</a>
        <a
            class="footer-link-list-more font-16 cursor-hand"
            :style="{
              color: (bgColor === '' ? '#A91728' : bgColor)
            }"
            @click="$navToByType(footer.more)"
        >了解更多</a>
      </div>
    </div>
    <div class="footer-bar main-width-625">
      <div class="footer-bar-left">
<!--        <img class="footer-bar-left-img1" src="/static/img/footer_logo1.png" alt="">-->
<!--        <img class="footer-bar-left-img2 margin-left-1vw" src="/static/img/footer_logo2.png" alt="">-->
      </div>
      <div class="footer-bar-right">
        <div class="footer-bar-item text-center"
             :style="{
                backgroundColor: (subBgColor === '' ? '#980028' : subBgColor),
             }"
        >
          <a class="flex-item-width50 cursor-pointer" @click="$navToByType(footer.about)">关于我们</a>
          <a class="flex-item-width50 cursor-pointer" @click="$navToByType(footer.sitemap)">站点地图</a>
          <a class="flex-item-width50 cursor-hand" @click="showSuggDialog">建议意见</a>
          <a class="flex-item-width50 cursor-hand" @click="showDialog('statement')">法律声明</a>
        </div>
        <div class="footer-bar-item" style="width: 35vw;"
             :style="{
                backgroundColor: (subBgColor === '' ? '#980028' : subBgColor),
             }"
        >
          <div class="footer-bar-right-concat">
            <a class="flex-item-width100 margin-bottom-1vw">{{footer.worktime}}</a>
            <a class="flex-item-width100 margin-bottom-1vw">{{footer.tel}}</a>
            <a class="flex-item-width100 margin-bottom-1vw cursor-hand" @click="showDialog('tips')">关于防范仿冒网站风险的提示</a>
          </div>
          <div class="footer-bar-right-qrcode flex-space-between margin-left-2vw">
            <a >
              <div><img :src="qiniuURL+footer.public.path" alt=""></div>
              <div class="text-center"><span>微信公众号</span></div>
            </a>
            <a class="margin-left-1vw">
              <div><img :src="qiniuURL+footer.miniprog.path" alt=""></div>
              <div  class="text-center"><span>微信小程序</span></div>
            </a>
          </div>
        </div>
      </div>
    </div>
    <div class="footer-msg">
      <div class="flex-space-around main-width-625">
        <div>{{footer.owner}}</div>
        <div class="beian">
          <a href="https://beian.miit.gov.cn/" target="_blank">备案号: {{footer.record}}</a>
        </div>
<!--        <div>京ICP公安备130161234号-3</div>-->
      </div>
    </div>
<!--    遮罩弹窗-->
    <el-dialog
        :title="dialogTitle"
        :visible.sync="dialogVisible"
        width="45%">
      <p>{{dialogContent}}</p>
    </el-dialog>
<!--    建议意见-->
    <el-dialog title="建议意见" :visible.sync="suggDialogVisible">
      <el-form >
        <el-form-item label="请输入建议意见" >
          <el-input v-model="suggDialogContent" type="textarea" :rows="4"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
<!--        <el-button @click="dialogFormVisible = false">取 消</el-button>-->
        <el-button type="primary" @click="suggSubmit">提 交</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { qiniuURL } from "@/api/common"
export default {
  name: 'cFooter',
  props: {
    bgColor: {
      type: String,
      default: '#A91728'
    },
    subBgColor: {
      type: String,
      default: '#980028'
    },
    footer: {
      type: Object,
      default: function () {
        return {
          about: '',
          sitemap: '',
          statement: '',
          worktime: '',
          tips: '',
          public: {
            path: ''
          },
          miniprog: {
            path: ''
          },
          owner: '',
          record: '',
        }
      }
    }
  },
  data() {
    return {
      qiniuURL: qiniuURL,
      // 法律声明、风险提示弹窗
      dialogVisible: false,
      dialogTitle: '',
      dialogContent: '',
      // 意见建议弹窗
      suggDialogVisible: false,
      suggDialogContent: '',
    }
  },
  methods: {
    showDialog(type) {
      switch (type){
        case 'statement':
          this.dialogTitle = '法律声明'
          this.dialogContent = this.footer.statement
          break
        case 'tips':
          this.dialogTitle = '风险提示'
          this.dialogContent = this.footer.tips
          break
      }
      this.dialogVisible = true
    },
    // 显示意见提交弹窗
    showSuggDialog() {
      this.suggDialogVisible = true
    },
    // 意见提交
    suggSubmit() {
      this.$message.success('提交成功')
      setTimeout(()=>{
        this.suggDialogContent = ''
        this.suggDialogVisible = false
      }, 500)
    }
  }
}
</script>

<style scoped>
.margin-left-1vw{
  margin-left: 1vw;
}
.margin-left-2vw{
  margin-left: 2vw;
}
.margin-left-3vw{
  margin-left: 3vw;
}
.flex-space-between{
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.footer-link-list-more{
  /*font-size: 1vw;*/
  color: #A91728;
  background-color: #FFFFFF;
  padding: 0.5vw 1vw;
  border-radius: 0.3vw;
}
.footer-link{
  /*font-size: 1.2vw;*/
  background: #CACACA;
  color: #333333;
  padding: 1.5vw 0;
  font-weight: bold;
}
.footer-body{
  color: white;
  width: 100%;
}
.footer-bar-right-concat{
  width: 52%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.footer-bar-right-qrcode{
  width: 40%;
}
.footer-bar-item img{
  width: 5vw;
  height: 5vw;
}
.flex-item-width50{
  display: block;
  width: 50%;
}
.flex-item-width100{
  display: block;
  width: 100%;
}
.text-center{
  text-align: center;
}
.margin-bottom-1vw{
  margin-bottom: 1vw;
}
.footer-bar-item{
  height: 7vw;
  display: flex;
  flex-wrap: wrap;
  font-size: 0.8vw;
  padding: 1vw 1vw;
  margin-left: 1vw;
}
.footer-bar-right{
  display: flex;
  justify-content: flex-start;
  width: 55vw;
}
.footer-bar-left{
  width: 10vw;
}
.footer-bar-left-img1{
  width: 3vw;
  height: 3vw;
}
.footer-bar-left-img2{
  width: 5vw;
  height: 3vw;
}
.footer-bar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 2vw 0;
}

.footer-msg {
  font-size: 0.8vw;
  padding: 1vw 0;
  background: #dabb85;
  color: black;
}
.flex-space-around{
  display: flex;
  justify-content: space-around;
  align-items: center;
}
span {
  cursor: pointer;
}
.beian a{
  /* text-decoration: none; */
  color: #000;
}
</style>
