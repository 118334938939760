var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"footer-body",style:({
        backgroundColor: (_vm.bgColor === '' ? '#A91728' : _vm.bgColor)
      })},[_c('div',{staticClass:"footer-link"},[_c('div',{staticClass:"footer-link-list main-width-625 flex-space-between"},[_c('a',{staticClass:"font-18 cursor-hand",on:{"click":function($event){return _vm.$navToByType(_vm.footer.qs)}}},[_vm._v("全方位为您解答相关的法律疑惑问题")]),_c('a',{staticClass:"footer-link-list-more font-16 cursor-hand",style:({
              color: (_vm.bgColor === '' ? '#A91728' : _vm.bgColor)
            }),on:{"click":function($event){return _vm.$navToByType(_vm.footer.more)}}},[_vm._v("了解更多")])])]),_c('div',{staticClass:"footer-bar main-width-625"},[_c('div',{staticClass:"footer-bar-left"}),_c('div',{staticClass:"footer-bar-right"},[_c('div',{staticClass:"footer-bar-item text-center",style:({
                backgroundColor: (_vm.subBgColor === '' ? '#980028' : _vm.subBgColor),
             })},[_c('a',{staticClass:"flex-item-width50 cursor-pointer",on:{"click":function($event){return _vm.$navToByType(_vm.footer.about)}}},[_vm._v("关于我们")]),_c('a',{staticClass:"flex-item-width50 cursor-pointer",on:{"click":function($event){return _vm.$navToByType(_vm.footer.sitemap)}}},[_vm._v("站点地图")]),_c('a',{staticClass:"flex-item-width50 cursor-hand",on:{"click":_vm.showSuggDialog}},[_vm._v("建议意见")]),_c('a',{staticClass:"flex-item-width50 cursor-hand",on:{"click":function($event){return _vm.showDialog('statement')}}},[_vm._v("法律声明")])]),_c('div',{staticClass:"footer-bar-item",staticStyle:{"width":"35vw"},style:({
                backgroundColor: (_vm.subBgColor === '' ? '#980028' : _vm.subBgColor),
             })},[_c('div',{staticClass:"footer-bar-right-concat"},[_c('a',{staticClass:"flex-item-width100 margin-bottom-1vw"},[_vm._v(_vm._s(_vm.footer.worktime))]),_c('a',{staticClass:"flex-item-width100 margin-bottom-1vw"},[_vm._v(_vm._s(_vm.footer.tel))]),_c('a',{staticClass:"flex-item-width100 margin-bottom-1vw cursor-hand",on:{"click":function($event){return _vm.showDialog('tips')}}},[_vm._v("关于防范仿冒网站风险的提示")])]),_c('div',{staticClass:"footer-bar-right-qrcode flex-space-between margin-left-2vw"},[_c('a',[_c('div',[_c('img',{attrs:{"src":_vm.qiniuURL+_vm.footer.public.path,"alt":""}})]),_vm._m(0)]),_c('a',{staticClass:"margin-left-1vw"},[_c('div',[_c('img',{attrs:{"src":_vm.qiniuURL+_vm.footer.miniprog.path,"alt":""}})]),_vm._m(1)])])])])]),_c('div',{staticClass:"footer-msg"},[_c('div',{staticClass:"flex-space-around main-width-625"},[_c('div',[_vm._v(_vm._s(_vm.footer.owner))]),_c('div',{staticClass:"beian"},[_c('a',{attrs:{"href":"https://beian.miit.gov.cn/","target":"_blank"}},[_vm._v("备案号: "+_vm._s(_vm.footer.record))])])])]),_c('el-dialog',{attrs:{"title":_vm.dialogTitle,"visible":_vm.dialogVisible,"width":"45%"},on:{"update:visible":function($event){_vm.dialogVisible=$event}}},[_c('p',[_vm._v(_vm._s(_vm.dialogContent))])]),_c('el-dialog',{attrs:{"title":"建议意见","visible":_vm.suggDialogVisible},on:{"update:visible":function($event){_vm.suggDialogVisible=$event}}},[_c('el-form',[_c('el-form-item',{attrs:{"label":"请输入建议意见"}},[_c('el-input',{attrs:{"type":"textarea","rows":4},model:{value:(_vm.suggDialogContent),callback:function ($$v) {_vm.suggDialogContent=$$v},expression:"suggDialogContent"}})],1)],1),_c('div',{staticClass:"dialog-footer",attrs:{"slot":"footer"},slot:"footer"},[_c('el-button',{attrs:{"type":"primary"},on:{"click":_vm.suggSubmit}},[_vm._v("提 交")])],1)],1)],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"text-center"},[_c('span',[_vm._v("微信公众号")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"text-center"},[_c('span',[_vm._v("微信小程序")])])
}]

export { render, staticRenderFns }